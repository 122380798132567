<script>
// const serverSrc = window.location.origin;
// const serverSrc = "http://192.168.101.72";
//const serverSrc = "https://www.weprofly.com/";
// const serverSrc = "http://test.spaceddd.com/"
// const serverSrc = "api/"
let isPro = true
const serverSrc = process.env.NODE_ENV === 'development' ?'api/':isPro ? 'https://www.weprofly.com/': 'http://test.spaceddd.com/'
const hasEnter = false;
const userSite = "中国";
const token = "123";
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NzQ3Mzg3MzgsInVzZXJfbmFtZSI6IjE4MjExODE1NTE4IiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9VU0VSIl0sImp0aSI6IjY1YjMwZTgxLWIzMzUtNDQ1Ni1hYzczLWJkNTk3NThlNGRkNiIsImNsaWVudF9pZCI6IlBLQkFtZGlIZHNLczZkc0RLUyIsInNjb3BlIjpbImFsbCIsInJlYWQiLCJ3cml0ZSJdfQ.HxsZSjAo57LUddMmpoLMuU8O5MPrhYu41uUlDQgILVg";
export default {
  token,
  userSite, //用户地址
  serverSrc, //服务器地址
  hasEnter //用户登录状态
};
</script>