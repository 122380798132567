<template>
  <div class="bannerBox">
    <div @click="next" class="height100">
      <swiper :options="swiperOption" ref="mySwiper" v-if="imgs.length > 0">
        <swiper-slide
            class="swiper-slide swiper-item"
            v-for="(item, index) in imgs"
            :key="index"
        >
          <div class="banners">
            <div class="swiper-slide ">
              <div v-if="!vMobile()" class="bg-image img-scale width100 height100" :style="`background-image:url(${item.img});`"></div>
              <div v-else class="bg-image img-scale width100 height100" :style="`background-image:url(${item.img});`"></div>
               
              <!-- <img v-if="!vMobile()" v-bind:src="item.img" alt class="img-scale"/>
              <img v-else :src="item.img_mobile" alt class="img-scale"/> -->

            </div>
            <div class="banner_text">
              <!-- <div class="style1">
                <div class="titBox">
                  <p class="tit_zh">{{ item.title }}</p>
                  <p class="tit_en">{{ item.sub_title }}</p>
                </div>
              </div> -->
              <div class="objName">
                <span class="objNameText">{{ item.bottom_title }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!-- 分页器 -->
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        <!-- 左右箭头 -->
        <!--<div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>-->
      </swiper>
    </div>
    <Loging v-show="isloging"/>
  </div>
</template>

<style lang="scss" scoped>
.bg-image {background-size: cover;background-position: center;background-repeat: no-repeat;}
.swiper-container {
  height: 100%;
  width: auto;
}

.swiper-item {
  .img-scale {
    transition: transform ease 60s, opacity ease 0.1s;
    transform: scale(1);

  }
  &.swiper-slide-active {
    .img-scale {
      transform: scale(2);

    }
  }


}
.height100 {height: 100%;}
.bannerBox {
  width: 100%;
  height: 100vh;
  position: relative;
  .banners {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
    .banner_text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      .style1 {
        width: 584px;
        height: 110px;
        margin: 0% auto;
        margin-top: 20.6%;
        // border: 15px solid #fff;
        background-image: url("../assets/images/banner_logo.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        // padding-top: 2.5%;
        // padding-left: 3%;
        .titBox {
          padding: 42px 0px 0px 126px;
          width: 400px;
          .tit_zh {
            // height: 54px;
            font-size: 16px;
            font-weight: 800;
            color: rgba(255, 255, 255, 1);
            // line-height: 54px;
          }
          .tit_en {
            // height: 22px;
            font-size: 16px;
            font-family: Helvetica;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .objName {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 27px;
        position: absolute;
        bottom: 50px;
        left: 88px;
        color: #fff;
      }
    }
  }
}
@media (max-width: 768px) {
  .bannerBox {
    height: 100vh;
    .banners {
     

      
      .banner_text {
        .style1 {
          width: 6.82rem;
          height: 1.4rem;
          margin: 0% auto;
          margin-top: 5rem;
          background: url("../assets/images/banner_logo.png") 50% 50%/6.82rem
          1.4rem no-repeat;
          .titBox {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            padding: 0.35rem 0 0 1.4rem;
            .tit_zh {
              font-size: 0.16rem;
              font-family: "Source Han Sans CN";
              font-weight: 800;
              color: rgba(255, 255, 255, 1);
              line-height: initial;
              height: auto;
              width: 3.3rem;
            }
            .tit_en {
              height: auto;
              line-height: initial;
              font-size: 0.14rem;
              font-family: Helvetica;
              font-weight: 400;
              width: 3.3rem;
              color: rgba(255, 255, 255, 1);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
        .objName {
          font-size: 0.22rem;
          line-height: 0.27rem;
          position: absolute;
          bottom: 0.5rem;
          left:0.88rem;
        }
      }
    }
  }
}

</style>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Loging from "@/components/loging.vue";
export default {
  data() {
    return {
      imgs: "",
      isloging: true,
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        //设置点击箭头

        //navigation: {
        //    nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // },
        //自动轮播
        paginationClickable: true,
        effect : 'fade',
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        // autoplay: {
        //   delay: 2000,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: false,
        // },
        //开启循环模式
        loop: true,

      },
    };
  },
  watch: {
    value(now, old) {
      this.isloging = now
    },
    isloging(now, old) {
      this.$emit('input', now)
    }
  },
  computed: {
    // imgs() {
    //   return this.$t("m.imgs");
    // },
    mySwiper() {
      return this.$refs.mySwiper.swiper
    }

  },
  components: {
    Swiper,
    SwiperSlide,
    Loging
  },
  mounted() {
    this.getBannerImg();

  },
  created() {

  },
  methods: {

    next() {
      this.mySwiper.slideNext;
    },
    getBannerImg() {
      let _this = this;
     // this.imgs = [{img: require('@/assets/images/banner0.png')},{img: require('@/assets/images/banner1.png')},{img: require('@/assets/images/banner3.png')},{img: require('@/assets/images/banner4.png')}];
      this.axios({
        url: _this.GLOBAL.serverSrc + "/wc/banners",
        method: "get",
        data: {},
        headers: {},
      })
          .then((response) => {
            if (response.data.code == "1") {
              
              this.imgs = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error); //请求失败返回的数据
          });
    },
    vMobile() {
      let ua = navigator.userAgent;
      let ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
          isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
          isAndroid = ua.match(/(Android)\s+([\d.]+)/),
          isMobile = isIphone || isAndroid;
      //判断
      if (isMobile) {
        return true;
      } else {
        return false;
      }
    },
  },
  directives: {
    swiper: directive,
  },
};
</script>