<template>
  <div class="sidebar pointer" :class="langText == 'En'? '':'english'">
    <img
      :class="istranfromsOne ? 'tranfromsOne' : ''"
      @click="backIndex"
      :style="myfirst"
      src="../assets/images/Logo.png"
      alt
      class="logo"
    />
    <div class="sidebar_main">
      <ul class="sidebar_top  width100 " style="height: 80vh;overflow: hidden;">
        <li
          class="sidebar_item  view align-center "
          :class="defnav == index ? 'active' : ''"
          @mouseenter.stop="setclicknav(index)"
          @mouseleave.stop="hideclicknav(index)"
          v-for="(item, index) in sidebarList"
          :key="index"
        >
          <!-- <a href="javascript:;" class="sidebarNav_text" :class="item.active">{{
            item.name
          }}</a> -->
          <router-link :to="item.path" class="sidebarNav_text first-nav sub_text_en" :class="item.active">{{
                item.name
              }}</router-link>
          <!-- <a
            v-if="item.active"
            class="sidebar_item_child"
            v-for="(citem,index1) in item.child"
            :key="index1"
          >{{citem.name}}</a>-->
          <ul v-show="defnav == index" class="view align-center justify-center c-b-c" style="margin-bottom: 40px;width:20px;" :class="index == 2 ? 'marginb0':''">
            <li
              :class="defnavChild == index1 ? 'active' : ''"
              v-for="(item1, index1) in item.child"
              class="block"
              @click.stop="setclicknavChild(index1)"
              :key="index1"
            >
              <router-link :to="item1.path" class="sidebarNav_text sub_text_en">{{
                item1.name
              }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
      <!-- <div @click="go_home" class="sidebar_home">
        {{backHome}}
      </div> -->
      <div class="sidebar_bottom" @click="changeLaguages">{{ langText }}</div>
    </div>

    <div v-show="isMobile" class="mobileNav">
      <ul class="oneNav">
        <!-- <li
          @click="defnav=index"
          :class="defnav==index?'active':''"
          v-for="(nav1,index) in sidebarList"
          :key="index"
        >
          <a :href="nav1.nav">{{nav1.name}}</a>
          <ul v-show="defnav==index" class="twoNav">
            <li v-for="(nav2,index2) in nav1.child" :key="index2">{{nav2.name}}</li>
          </ul>
        </li>-->
        <li
          class="sidebar_item"
          :class="defnav == index ? 'active' : ''"
          @click.stop="setclicknav(index)"
          v-for="(item, index) in sidebarList"
          :key="index"
        >
          <a href="javascript:;" class="sidebarNav_text fz-16" :class="item.active">{{
            item.name
          }}</a>
          <ul v-show="defnav == index" class="fz-16 " style="margin:0 auto;margin-bottom: 20px;">
            <li
              :class="defnavChild == index1 ? 'active' : ''"
              v-for="(item1, index1) in item.child"
              @click.stop="setclicknavChild(index1)"
              :key="index1"
            >
              <router-link :to="item1.path" class="sidebarNav_text fz-16">{{
                item1.name
              }} </router-link>

            </li>
          </ul>
        </li>
      </ul>

      <div class="navFoot">
        <p @click="go_home" class="close">
          {{ backHome }}
          <!-- <img src="../assets/images/cha.png" alt /> -->
        </p>
        <p @click="changeLaguages" class="lang">{{ langText }}</p>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      activeID: 0,
      defnav: -1,
      defnavChild: 0,
      myfirst: {},
      isMobile: false,
      istranfromsOne: false,
    };
  },
  // props: ["sidebarList"],
  computed: {
    sidebarList() {
      console.log(this.$t("m.sidebarList"))
      return this.$t("m.sidebarList");
    },
    langText() {
      return this.$t("m.langText");
    },
    backHome() {
      return this.$t("m.backHome");
    },
    vMobile() {
      let ua = navigator.userAgent;
      let ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid;
      //判断
      if (isMobile) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    // 使用watch 监听$router的变化
    $route(to, from) {
      this.isMobile = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    backIndex() {
      if (this.$route.name.indexOf('Home') > -1 && window.innerWidth > 768) {
        window.location.reload()
      } else {
        if (this.vMobile) {
          this.isMobile = !this.isMobile;
        } else {
          this.isMobile = false;
          this.$router.push({ path: "/" });
        }
      }
      
    },
    changeLaguages() {
      console.log(this.$i18n.locale);
      let lang = this.$i18n.locale === "zh-CN" ? "en-US" : "zh-CN";
      this.$i18n.locale = lang;
    },
    setclicknavChild(i) {
      // setTimeout(() => {
      this.defnavChild = i;
      window.removeEventListener("scroll", this.handleScroll);
      this.istranfromsOne = true;
      setTimeout(() => {
        this.istranfromsOne = false;
        window.addEventListener("scroll", this.handleScroll);
      }, 1000);
    },
    setclicknav(i) {
      if (this.defnav != i) {
        this.defnav = i;
      }
      if (!this.vMobile) {
        window.removeEventListener("scroll", this.handleScroll);
        this.istranfromsOne = true;
        setTimeout(() => {
          this.istranfromsOne = false;
          window.addEventListener("scroll", this.handleScroll);
        }, 1000);
      }
      
    },
    hideclicknav(i) {
      window.removeEventListener("scroll", this.handleScroll);
      this.istranfromsOne = true;
      if (this.defnav != -1) {
        this.defnav = -1;
      }
      
      setTimeout(() => {
        this.istranfromsOne = false;
        window.addEventListener("scroll", this.handleScroll);
      }, 1000);
    },

    handleScroll() {
      // 滚动条长度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let fullHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (!scrollTop % fullHeight == 0) {
        this.myfirst = {
          transform: "rotate(" + (scrollTop / fullHeight) * 360 + "deg)",
        };
      }
      // let scrollPosition = scrollTop / fullHeight;
      // if (scrollPosition < 1.5) {
      //   this.defnav = 0;
      // } else if (scrollPosition < 2.5) {
      //   this.defnav = 1;
      // } else if (scrollPosition < 3.5) {
      //   this.defnav = 2;
      // } else if (scrollPosition < 4.5) {
      //   this.defnav = 3;
      // } else if (scrollPosition < 5.5) {
      //   this.defnav = 4;
      // }
    },
    go_home() {
      this.isMobile = false;
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style  lang="scss" scoped>
.mobileNav {
  display: none;
}
.sidebar {
  position: fixed;
  right: 0;
  z-index: 199;
  display: flex;
  height: 100%;
}
.block {display: block;width:20px;}
.marginb0 {margin-bottom:0 !important;}
.logo {
  width: 98px;
  height: 98px;
  position: fixed;
  z-index: 999;
  right: 40px;
  top: 20px;
  margin-top: 20px;
  transform: rotate(0);
}
/* .sidebar_item a .active,  */
.router-link-exact-active,
.router-link-active {
  color: #f68732 !important;
}

.sidebar_item:hover .first-nav{font-size: 18px;color: #f68732 !important;}
.sidebarNav_text {
  color: #fff;
  font-weight: 600;
}
.sidebarNav_text:hover{
  color: #f68732 !important;
}
@-webkit-keyframes myfirst_in {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes myfirst_in {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.tranfromsOne {
  -webkit-animation: myfirst_in 1s;
  animation: myfirst_in 1s;
}
.sidebar_main {
  height: 100%;
  width: 40px;
  background: #0569d3;
  padding: 44px 0;
  letter-spacing: 3px;
  font-size: 14px;
  display: flex;
  padding-top: 75px;
  align-items: center;
  flex-direction: column;
  /*justify-content: space-between;*/
  color: #fff;
  position: relative;
}
/* li.active > a {
  color: rgba(233, 128, 35, 1);
} */
.sidebar_item {
  cursor: pointer;
  text-align: center;
}
.sidebar_top .sidebar_item ul li a {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: -15px;
}
.sidebar_bottom {
  position: absolute;
  bottom: 50px;
  cursor: pointer;
}


.sidebar_home {
  position: absolute;
  bottom: 100px;
  cursor: pointer;
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
}
.sidebar_item_child {
  margin-top: 20px;
  display: block;
  color: #fff;
  font-size: 12px;
}

@media (min-width: 768px) {
  .sidebar_item a {
    margin: 0 0 36px 0;
    width: 20px;
    writing-mode:none;
    display: block;
    transition: all ease .5s;
  }
  .english {
    .sidebarNav_text {
      &.sub_text_en {
        writing-mode: vertical-rl !important;
        -webkit-writing-mode: vertical-rl !important;
        -ms-writing-mode: vertical-rl !important;
        margin-bottom: 20px;
      }
    }
    .c-b-c {margin-right: 6px;}
  }
  
}
@media (max-width: 768px) {
  .sidebar_main {
    display: none;
  }
  .sidebar_item a {
    margin: 0 0 0.37rem 0;
  }
  .sidebar_item a {
    margin: 0 0 36px 0;
    writing-mode: vertical-rl;
    display: block;
    transition: all ease .3s;
  }
  .mobileNav {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(1, 30, 88, 0.8);
    color: #fff;
  }

  .twoNav {
    margin: 0.2rem;
  }
  .navFoot {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
  }
  .close,
  .lang {
    text-align: center;
    cursor: pointer;
    font-size: 0.35rem;
    font-family: "Source Han Sans CN";
    font-weight: 400;
    margin: 0.35rem 0 0 0;
    color: rgba(255, 255, 255, 1);
  }
  .close img {
    width: 0.4rem;
    height: 0.4rem;
  }
  .mobileNav li {
    text-align: center;
    cursor: pointer;
    font-family: "Source Han Sans CN";
    font-weight: 400;
    margin: 0.1rem 0;
    ul li a {
      font-weight: 400;
    }
  }
  .mobileNav li > a {
    color: rgba(255, 255, 255, 1);
    writing-mode: inherit;
    -webkit-writing-mode: inherit;
    -ms-writing-mode: inherit;
  }
  .oneNav > li {
    margin-bottom: 1rem;
  }
  .oneNav {margin-top:20%;}
  /* .mobileNav li.active > a {
    color: rgba(233, 128, 35, 1);
  } */
  .logo {
    width: 1rem;
    position: fixed;
    right: 0.4rem;
    top: 0.36rem;
    height: 1rem;
    margin-top: 0px;
  }
}
</style>
