import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{index:0},
    component: Home
  },
  {
    path: '/mainlist1',
    name: 'mainlist1',
    component: Home

  },
  {
    path: '/mainlist2',
    name: 'mainlist2',
    component: Home

  },
  {
    path: '/mainlist3',
    name: 'mainlist3',
    component: Home

  },
  {
    path: '/mainlist4',
    name: 'mainlist4',
    component: Home

  },
  {
    path: '/mainlist5',
    name: 'mainlist5',
    component: Home
  },
  {
    path: '',
    name: 'home',
    component: () => import('../views/Twoview.vue'),
    children: [
      {
        path: '/on',
        name: 'on',
        component: () => import('../views/Company.vue')
      },
      {
        path: '/project',
        name: 'project',
        meta:{index:1},
        component: () => import('../views/Project.vue'),
      },
      {
        path: '/projectInfo/:id',
        name: 'projectInfo',
        meta:{index:1},
        component: () => import('../views/projectInfo.vue')
      },
      {
        path: '/search/:ajaxUrl/:value',
        name: 'search',
        meta:{index:1},
        component: () => import('../views/Search.vue')
      },
      {
        path: '/repository',
        name: 'repository',
        meta:{index:1},
        component: () => import('../views/Repository.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        meta:{index:1},
        component: () => import('../views/Contact.vue')
      },
      {
        path: '/jobs',
        name: 'jobs',
        meta:{index:1},
        component: () => import('../views/Jobs.vue')
      },
      {
        path: '/company',
        name: 'company',
        meta:{index:1},
        component: () => import('../views/Company.vue')
      }, {
        path: '/team',
        name: 'team',
        meta:{index:1},
        component: () => import('../views/Team.vue'),
        children: []
      }, {
        path: '/teamInfo/:id',
        name: 'teamInfo',
        meta:{index:2},
        component: () => import('../views/TeamInfo.vue')

      }, {
        path: '/resource',
        name: 'resource',
        meta:{index:1},
        component: () => import('../views/Resource.vue')
      }, {
        path: '/planning',
        name: 'planning',
        meta:{index:1},
        component: () => import('../views/Planning.vue')
      }, {
        path: '/news/:year',
        name: 'news',
        meta:{index:1},
        component: () => import('../views/News.vue')
      }, 
      {
        path: '/news',
        name: 'news',
        meta:{index:1},
        component: () => import('../views/News.vue')
      }, 
      {
        path: '/newsInfo/:id',
        name: 'newsInfo',
        meta:{index:2},
        component: () => import('../views/NewsInfo.vue')
      },{
        path: '/observe/:year',
        name: 'observe',
        meta:{index:1},
        component: () => import('../views/Observe.vue')
      },{
        path: '/observe',
        name: 'observe',
        meta:{index:1},
        component: () => import('../views/Observe.vue')
      },
      {
        path: '/observeInfo/:id',
        name: 'observeInfo',
        meta:{index:2},
        component: () => import('../views/ObserveInfo.vue')
      }, {
        path: '/city',
        name: 'city',
        meta:{index:1},
        component: () => import('../views/Project.vue')
      }, {
        path: '/architecture',
        name: 'architecture',
        meta:{index:1},
        component: () => import('../views/Project.vue')
      },
      {
        path: '/park',
        name: 'park',
        meta:{index:1},
        component: () => import('../views/Project.vue')
      }, {
        path: '/about',
        name: 'about',
        meta:{index:1},
        component: () => import('../views/About.vue')
      }
    ],
  },



]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
