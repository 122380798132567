<template>
  <div class="footer" >
    <ul class="fade-in  view flex-row align-start justify-center" v-if="langText == 2">
      <li v-for="(item,index) in footData" :key="index" class="flex-1 view align-center justify-start">
        <router-link class="hor" :to="item.path">
          <h5 >{{item.name}}</h5>
        </router-link>
        <router-link class="hor" :to="item1.path" v-for="(item1,index1) in item.child" :key="index1">
          <p >{{item1.name}}</p>
        </router-link>
      </li>
    </ul>

    <ul class="fade-in  view flex-row align-start justify-start p-l-10 p-r-10" style="margin-right:-15px;" v-else-if="langText == 1">
      <li v-for="(item,index) in footData" :key="index" class="flex-1 view align-start justify-start p-r-15">
        <router-link class="hor" :to="item.path">
          <h5 class="fzc-14">{{item.name}}</h5>
        </router-link>
        <router-link class="hor" :to="item1.path" v-for="(item1,index1) in item.child" :key="index1">
          <p class="fzc-12">{{item1.name}}</p>
        </router-link>
      </li>
    </ul>
    <div class="disimg">
    <img class="logo2img" src="../assets/images/Logo2.png" alt />
    </div>
    <p class="cpright"><a style="color:#fff !important;" href="https://beian.miit.gov.cn">粤ICP备2020077372号</a>  深圳明日湾创创意咨询有限公司</p>
  </div>
  
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    footData() {
      return this.$t("m.sidebarList");
    },
    langText() {
      
      if (this.$t("m.langText") == '中' && window.innerWidth <= 768) {
        console.log(124123414)
        return 1
      }
      return 2
    },
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  text-align: center;
  background: rgba(8,64,151,255);
  position: relative;
  margin:auto;
  ul {
    width: 100%;
    position: absolute;
    bottom:380px;
    li {
      font-weight: bold;
      text-align: left;
      h5 {
        font-weight: bold;
        color: #fff;
        font-size: 18px;
        text-align: left;
        margin-bottom: 20px;
      }
      p {
        font-size: 14px;
        margin-top: 6px;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .disimg{
    width: 100%;
    margin:auto;
    position: absolute;
    bottom: 100px;
    padding-right: 40px;
  }
  .logo2img {
      width: 300px;
  }
  > p {
    color: #fff;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 50px;
    padding-right: 40px;
  }
}
@media (min-height: 768px) {
  .logo2img {
    margin-top: 24%;
    width: 300px;
  }
}
.fzc-14 {font-size: 14px !important;}
.fzc-12 {font-size: 12px !important;}
@media (max-width: 768px) {
  .footer .disimg {
    padding-right: 0px;
    img {
      width: 60%;
    }
  }
  .logo2img {margin-left: 2px;}
  .footer > p{padding-right: 0px;bottom:30px;}
  .footer ul li {
    text-align: center;
    padding-left: 0rem;
  }
  .footer ul li h5{
    text-align: center;
  }
  .footer > p{
    font-size: 12px;
  }
  .footer .logo2img{
    // width: 100%;
  }
}
.hor {
  transition: all ease .3s;
  cursor: pointer;
}
.hor:hover *{
  color: #f68732 !important;
}
.cpright{
  font-size: 8px;
  padding-top: 30px;
}
</style>