export const m = {
  langText: 'En',
  backHome:"回到首页",
  imgs: [
    {
      img: require("../../assets/images/banner0.png"),
      imgMobile: require("../../assets/images/banner_mobile0.png"),
      styleType: 1,
      title_zh: "创意驱动美好生活",
      title_en: "Creativity drives a good life"
    },
    {
      img: require("../../assets/images/banner1.png"),
      imgMobile: require("../../assets/images/banner_mobile1.png"),
      styleType: 1,
      title_zh: "最具实战经验的文旅创意落地平台",
      title_en:
        "The most practical experience of cultural and tourism creative landing platform"
    },
    {
      img: require("../../assets/images/banner2.png"),
      imgMobile: require("../../assets/images/banner_mobile2.png"),
      styleType: 2,
      texts: [
        {
          title_zh: "创意·点燃花火",
          title_en: "Creativity · Lighting fireworks"
        },
        {
          title_zh: "创意·驱动生活",
          title_en: "Creativity · Drive life"
        },
        {
          title_zh: "创意·洞见世界",
          title_en: "Creativity · Insight into the world"
        }
      ]
    },
    {
      img: require("../../assets/images/banner3.png"),
      imgMobile: require("../../assets/images/banner_mobile3.png"),
      styleType: 2,
      texts: [
        {
          title_zh: "灵感智团",
          title_en: "Inspiration think tank"
        },
        {
          title_zh: "创意实践家",
          title_en: "Creative practitioner"
        },
        {
          title_zh: "幻想工程师",
          title_en: "Fantasy engineer"
        }
      ]
    },
    {
      img: require("../../assets/images/banner4.png"),
      imgMobile: require("../../assets/images/banner_mobile4.png"),
      styleType: 1,
      title_zh: "创意始于行走，实践源于洞察。",
      title_en:
        "Creativity starts with walking, and practice starts with insight."
    }
  ],
  sidebarList: [
    {
      name: "关于",
      id: 0,
      path: '/company',
      nav: "#mainlist1",
      child: [
        {
          name: "公司",
          active: "",
          path: '/company',
          id: 0
        },
        {
          name: "团队", 
          path: '/team',
          active: "",
          id: 1
        },
        // {
        //   name: "客户", 
        //   path: '/resource',
        //   active: "",
        //   id: 2
        // }
      ]
    },
    {
      name: "业务",
      id: 1,
      active: "", 
      path: '/Planning',
      nav: "#mainlist2",
      child: [
        {
          name: "策划∞",
          active: "",
           path: '/Planning',
          id: 0
        },
      
      ]
    },
    {
      name: "项目",
      id: 2,
      active: "",
      path: '/project',
      nav: "#mainlist3",
      child: [
        {
          name: "城乡策划",
          active: "", 
          path: '/city?type_id=69',
          id: 0
        },
        {
          name: "建筑策划",
          active: "",
           path: '/architecture?type_id=70',
          id: 1
        },
        {
          name: "乐园策划",
           path: '/park?type_id=71',
          active: "",
          id: 1
        }
      ]
    },
    {
      name: "视野",
      id: 3,
      active: "",
      nav: "#mainlist4",
       path: '/news',
      child: [
        {
          name: "新闻",
          active: "",
          path: '/news', 
          id: 0
        },
        {
          name: "观点",
          active: "",
          path: '/observe',
          id: 1
        },
        {
          name: "知识库",
          active: "",
          path: '/repository',
          id: 2
        }
      ]
    },
    {
      name: "联系",
      id: 4,
      path: '/jobs',
      active: "",
      nav: "#mainlist5",
      child: [
        {
          name: "招聘",
          active: "",
          path: '/jobs',
          id: 0
        },
        {
          name: "联系",
          path: '/about',
          active: "",
          id: 1
        },
      ]
    }
  ],
  listData: [
    {
      title: "关于bicc",
      content: "bicc湾创以“创意驱动美好生活”为理念，旨在打造国内最具实战经验的创意落地平台。",
      list: [
        { name: "公司",path:"/company", active: "active", cName: "创意平台" },
        { name: "团队",path:"/team", cName: "创意单元" },
        // { name: "资源",path:"/resource", cName: "创意外脑" }
      ],
      styleCss: {
        top: "100%",
        backgroundImage: "url(" + require("../../assets/images/homeBg0.png") + ")"
      },
      moreText: "了解更多"
    }, {
      title: "bicc业务",
      content:
        "bicc湾创的“策划∞”，是一项为建筑、规划、景观等设计业务提供“任务书研究”的前置工作，它衍生于设计，但更关注设计以外的研发、投资、建设、运营等环节。",
      list: [{ name: "策划∞",path:"/Planning", active: "active" }],
      styleCss: {
        top: "200%",
        backgroundImage: "url(" + require("../../assets/images/homeBg1.png") + ")"
      },
      moreText: "了解更多"
    }, {
      title: "bicc项目",
      content:
        "bicc湾创以“策划∞”为核心驱动力，立足城乡策划、建筑策划、乐园策划三大领域，提供面向实施的创意服务，激发项目的机会与价值。",
      list: [{ name: "城乡策划",path:"/city", active: "active" }, { name: "建筑策划",path:"/architecture" }, { name: "乐园策划" ,path:"/park"}],
      styleCss: {
        top: "300%",
        backgroundImage: "url(" + require("../../assets/images/homeBg0.png") + ")"
      },
      moreText: "了解更多"
    }, {
      title: "bicc视野",
      list: [
        { name: "新闻",path:"/news", active: "active", cName: "传播咨询" },
        { name: "观点",path:"/observe", cName: "传递价值" },
        { name: "知识库",path:"/repository", cName: "传承梦想" }
      ],
      content:
        "bicc湾创坚信“创意”是项目的生命力和驱动力，通过对创意元素及其组合模式的不断更新、对综合开发知识库的不懈迭代，持续探究如何用新思维驱动新价值。",
      styleCss: {
        top: "400%",
        backgroundImage: "url(" + require("../../assets/images/homeBg3.png") + ")"
      },
      moreText: "了解更多"
    },
     {
      title: "联系bicc",
      list: [
        { name: "招聘",path:"/jobs", active: "active", cName: "传播咨询" },
        { name: "联系",path:"/about", cName: "传递价值" },
      ],
      content:"欢迎进一步了解bicc湾创，和我们一起探索美好未来的无限可能。",
      styleCss: {
        top: "500%",
        backgroundImage: "url(" + require("../../assets/images/homeBg4.png") + ")"
      },
      moreText: "了解更多"
    }
  ],
  sidebarListIndex: [
    {
      name: "关于",
      id: 0,
      nav: "#mainlist1",
    },
    {
      name: "业务",
      id: 1,
      active: "",
      nav: "#mainlist2",

    },
    {
      name: "项目",
      id: 2,
      active: "",
      nav: "#mainlist3",

    },
    {
      name: "视野",
      id: 3,
      active: "",
      nav: "#mainlist4",

    },
    {
      name: "联系",
      id: 4,
      active: "",
      nav: "#mainlist5",
      child: []
    }
  ],
  onHeaddata: {
    imgUrl: require('../../assets/images/head_img.png'),
    headTitZh: '以策划为源，衍化万物，为深远发展探溯顶层方略。',
    headTitEn: 'Take planification as a resource. Explore top strategies for long-term development.'
  },
  more: '更多',
  close: '关闭',
  projectHeaddatc: {
    imgUrl: require('../../assets/images/b-r-4.png'),
    headTitZh: '热爱知识，更热爱让经验与实践的积累得以无限叠加。',
    headTitEn: 'We love knowledge, especially the experience through practice.'
  },
  projectHeaddatb: {
    imgUrl: require('../../assets/images/b-r-4.png'),
    headTitZh: '以全球化与在地化的视角创想未来。',
    headTitEn: 'Locally and internationally, we imagine the future.'
  },
  projectHeaddata: {
    imgUrl: require('../../assets/images/b-r-4.png'),
    headTitZh: '洞察万物，捕捉风向，谨慎思辨，付诸实践。',
    headTitEn: 'Discover. Locate. Think. Practice.'
  },
  projectHeaddata5: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
    
  },

  projectHeaddata6: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
    

    
    
  },

  projectHeaddata7: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
    
  },

  projectHeaddata8: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
    
  },
  projectName: '所有项目',
  projectSubName: 'Projects',

  projectName1: '城乡策划',
  projectSubName1: 'Urban & Rural Planning',

  projectName2: '建筑策划',
  projectSubName2: 'Architecture Planning',

  projectName3: '乐园策划',
  projectSubName3: 'Theme Park Planning',


  repositoryHeaddata: {
    imgUrl: require('../../assets/images/repository_head_img.png'),
    headTitZh: '热爱知识， 更热爱让经验与眼界的积累得以无限叠加。',
    headTitEn: 'Love the knowledge, more love so that the accumulation of experience and vision can be infinitely superimposed.'
  },
  companyHeaddata: {
    imgUrl: require('../../assets/images/companyAddress.png'),
    headTitZh: '做生活的洞察者，拾起创意的火光，照亮未来。',
    headTitEn: 'Have an insight for life, ignite the sparkle of creativity, and light up the path to the future.'
  }
  ,
  companyHeaddata1: {
    imgUrl: require('../../assets/images/companyAddress.png'),
    headTitZh: '以数万小时共事项目的默契，齐心驱动创意。',
    headTitEn: 'Through thousands working hours as a team, we build creativity. '
  },
  companyHeaddata2: {
    headTitZh: '做生活的洞察者，拾起创意的火光，照亮未来。',
    headTitEn: 'To be an insight into life,Pick up the creative fire and illuminate the future.'
  },
  jobHeaddata: {
    imgUrl: require('../../assets/images/b-r-5.png'),
    headTitZh: '为事业征程寻觅旅伴，共赴美好生活目的地。',
    headTitEn: 'Be our partners on this journey to a better life.'
  },
  jobHeaddata1: {
    imgUrl: require('../../assets/images/b-r-5.png'),
    headTitZh: '从湾区远航，看世界归来。',
    headTitEn: 'Sail out from the bay to discover the world.'
  },
}
export default m
