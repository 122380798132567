<template>
  <div class="home">
    <!-- <Loging></Loging> -->
    <Sidebar2 :sidebar-list="listsData"></Sidebar2>
    
   <full-page :options="options" ref="fullpage" class="fullpage" >
      <div class="section">
           <Banner ></Banner>
      </div>
       <div class="mainlists section banSection"  v-for="(lists,index) in listData" :key="index" :id="'mainlist'+(index+1)">
          <div class="widthBox">
            <h1 class="title ">{{lists.title}}</h1>
            <div class="main_content ">{{lists.content}}</div>
            <ul class="nav">
              <li
                class="nav_item"
                :class="lists1.active"
                v-for="(lists1,index) in lists.list"
                :key="index"
              >
                <router-link :to="lists1.path">
                  <span class="nav_item_n ">{{lists1.name}}</span>
                </router-link>
                
              </li>
            </ul>
          
          </div>
        </div>

         <div class="section mainlists foot">
           <Foot></Foot>
         </div>
   </full-page>

    
    <div class="dianBox" id="showdianbox">
      <span class="dian"></span>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/banner.vue";
import Sidebar2 from "@/components/sidebar2.vue";
import Loging from "@/components/loging.vue";
import Foot from "@/components/footer2.vue";
import $ from 'jquery'
export default {
  name: "Home",
  data() {
    let that = this
    return {
      isloging: true,
      // listsData:this.$t("m.sidebarListIndex"),
      fadeInElements: [],
      isBannerInited: false,
      activeIndex: '',
      showBiccBox: true,
      options: {
       licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        scrollingSpeed: 600,
        scrollBar: true,
        afterLoad: (i, obj) => {
          if (!obj.isFirst) {
            
            $('#islogingId').hide()
          } else {
            $('#islogingId').show()
          }
          if (!obj.isLast && !obj.isFirst) {
            $('.banSection.active .title').css({
              'transform': 'translateX(0px)',
              'opacity': '1'
            })
            $('.banSection.active .main_content').css({
              'transform': 'translateX(0px)',
              'opacity': '1'
            })
            $('.banSection.active .nav_item').css({
              'transform': 'translateX(0px)',
              'opacity': '1'
            })
            
          }
          
          if (obj.isLast) {
            $('#showdianbox').hide()
          } else {
            $('#showdianbox').show()
          }
        },
        onLeave: function (i, direction) {
          if (!direction.isFirst) {
            $('#islogingId').hide()
          } 
          $('.banSection .title').css({
            'transform': 'translateX(-200px)',
            'opacity': '0'
          })
          $('.banSection .main_content').css({
            'transform': 'translateX(-200px)',
            'opacity': '0'
          })
          $('.banSection .nav_item').css({
              'transform': 'translateX(-200px)',
             'opacity': '0'
            })
          that.handleScroll()
        }
      }
    };
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  computed: {
    listsData() {
      return this.$t("m.sidebarListIndex");
    },
    listData() {
      return this.$t("m.listData");
    },
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
    // 监听鼠标滚动事件
    // document.addEventListener("scroll", this.handleScroll);
  },
  components: {
    Banner,
    Sidebar2,
    Loging,
    Foot,
  },
  methods: {
    initBanner(state) {
      this.isBannerInited = state
    },
    // 循环判断是否要触发过渡
    handleScroll() {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (this.isElemVisible(elem)) {
          elem.style.opacity = "1";
          elem.style.transform = "scale(1)";
          this.fadeInElements.splice(i, 1); // 只让它运行一次
        }
      }
    },

    // 判断元素距离窗口的位置
    isElemVisible(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top + 50; // 200 = buffer
      var elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
}
// .banSection {
//   .title {
//      transform: translateX(-200px); 
//      transition: transform ease .5s;
//   }
//   &.activeR {
//     .title {
//       transform: translateX(0px);
//     }
    
//   }
// }
.mainlists{
  position: relative;
  z-index: 100;
}
#mainlist1 {
  width: 100%;
  height: 100%;
  background: url("../assets/images/homeBg0.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#mainlist2 {
  width: 100%;
  height: 100%;
  background: url("../assets/images/homeBg1.png") 0 0/100% 100% no-repeat;
  background-size: cover;
}
#mainlist3 {
  width: 100%;
  height: 100%;
  background: url("../assets/images/homeBg2.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#mainlist4 {
  width: 100%;
  height: 100%;
  background: url("../assets/images/homeBg3.png");
  background-size: cover;
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#mainlist5 {
  width: 100%;
  height: 100%;
  background: url("../assets/images/homeBg4.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.widthBox {
  padding: 15% 0 0 18%;
  .title {
    font-size: 26px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 60px;
  }
  .main_content {
    font-size: 16px;
 
    font-weight: 400;
    width: 80%;
    color: rgba(255, 255, 255, 1);
    line-height: 26px;
    margin-top: 10px;
  }
  .moreBox {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(233, 128, 35, 1);
    line-height: 27px;
  }
  .more_icon {
    cursor: pointer;
    width: 22px;
    margin-left: 90px;
    height: 22px;
  }
}
.nav_item {transition: all ease .5s;}
.nav {
  margin-top: 30px;
}
.nav > li {
  margin-bottom: 20px;
}
.banSection {
  .title {transition:  all ease .3s;}
  .main_content {transition:  all ease .3s;}
}

.nav_item a span:hover {
  color: rgba(233, 128, 35, 1);
}
/deep/.footer {height: 100%;}

.nav_item_c {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  line-height: 27px;
}
.nav_item_n {
  margin-right: 90px;
  font-size: 30px;

  cursor: pointer;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 54px;
}
.homeFooter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 600%;
  background-color: #0e4093;
  /* z-index: 999; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.homeFooter_bottom {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.homeFooter_bottom > img {
  margin-bottom: 30px;
}
.address {
  background: #fff;
  width: 100%;
  font-size: 16px;

  font-weight: 400;
  color: rgba(14, 64, 147, 1);
  line-height: 24px;
  padding: 22px 0;
  text-align: center;
}
.footer_code {
  font-size: 12px;
  padding: 78px 0 25px 0;
  color: #fff;
  text-align: center;
}
html,
body {
  width: 100%;
  font-size: 20px;
}

@media (max-width: 768px) {
  .home {
    height: 100%;
  }
  #mainlist1 {
    width: 100%;
    // height: 100%;
    background: url("../assets/images/homeMobileBg0.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #mainlist2 {
    width: 100%;
    // height: 100%;
    background: url("../assets/images/homeMobileBg1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #mainlist3 {
    width: 100%;
    // height: 100%;
    background: url("../assets/images/homeMobileBg2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #mainlist4 {
    width: 100%;
    // height: 100%;
    background: url("../assets/images/homeMobileBg3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #mainlist5 {
    width: 100%;
    // height: 100%;
    background: url("../assets/images/homeMobileBg4.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .widthBox {
    padding: 2rem 0 0 0.76rem;
    .title {
      font-size: 20px;
      font-weight: 800;
      color: rgba(255, 255, 255, 1);
      line-height: 1.5;
    }
    .main_content {
      font-size: 14px;

      font-weight: 400;
      width: 5.3rem;
      color: rgba(255, 255, 255, 1);
      line-height: 1.5;
    }
    .moreBox {
      cursor: pointer;
      font-size: 0.16rem;
      font-weight: 500;
      cursor: pointer;
      color: rgba(233, 128, 35, 1);
      line-height: 0.27rem;
    }
    .more_icon {
      cursor: pointer;
      width: 0.44rem;
      margin-left: 0.9rem;
      height: 0.44rem;
    }
  }
  .nav {
    margin-top: 0.2rem;
  }
  .nav > li {
    margin-bottom: 0.2rem;
  }
  .nav_item_c {
    font-size: 0.32rem;

    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    line-height: 0.54rem;
  }
  .nav_item_n {
    margin-right: 0.9rem;
    font-size: 20px;
    font-family: Helvetica;
    cursor: pointer;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 1.5;
  }
  .homeFooter {
    position: absolute;
    width: 100%;
    height: 100%;
    top: initial;
    background-color: #0e4093;
    /* z-index: 999; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .homeFooter_bottom {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
  .homeFooter_bottom > img {
    margin-bottom: 0.3rem;
    width: 3rem;
    height: 1.12rem;
  }
  .address {
    background: #fff;
    width: 100%;
    font-size: 0.32rem;
    font-weight: 400;
    color: rgba(14, 64, 147, 1);
    line-height: 0.48rem;
    padding: 0.22rem 0;
    text-align: center;
  }
  .footer_code {
    font-size: 12px;
    padding: 0.78rem 0 0.25rem 0;
    color: #fff;
    text-align: center;
  }
  html,
  body {
    width: 100%;
  }
  .dianBox {
    height: 80px;
  }
}
</style>