<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
//home slide-left-leave-active slide-left-leave-to
//twoview slide-left-enter-active slide-left-enter-to

//twoview slide-right-leave-active slide-right-leave-to

#app {
  color: #808080;
  height: 100%;
  min-width: 1200px;
}
@keyframes move {
  from {
    margin-bottom: 10px;
    opacity: 0;
  }
  to {
    margin-bottom: 0px;
    opacity: 1;
  }
}
@media (max-width: 768px) {
  #app {
    min-width: auto;
  }
}

</style>

<script>
export default {
  name: "APP",
  data() {
    return {
      fadeInElements: [],
      transitionName: "",
    };
  },
  computed: {},
  watch: {
    // 使用watch 监听$router的变化
    $route(to, from) {
      // window.scrollTo(0, 0);
      // 如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        // 设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
  created() {
    // console.log(window.innerWidth);
    if (window.innerWidth < 768) {
      var fontSize = window.innerWidth / (750 / 100),
        html = document.querySelector("html");
      html.style.fontSize = fontSize + "px";
    }
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
    // 监听鼠标滚动事件
    document.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    // 循环判断是否要触发过渡
    handleScroll(evt) {
      if (this.fadeInElements.length == 0) {
        this.fadeInElements = Array.from(
          document.getElementsByClassName("fade-in")
        );
      }
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (this.isElemVisible(elem)) {
          elem.style.opacity = "1";
          elem.style.transform = "scale(1)";
          this.fadeInElements.splice(i, 1); // 只让它运行一次
        }
      }
    },
    // 判断元素距离窗口的位置
    isElemVisible(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top + 20; // 200 = buffer
      var elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
  },
};
</script>