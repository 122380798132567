<template>
  <div id="islogingId" style="z-index:2000;" ref="loading" v-show="isloging"  class="loging view justify-center align-center">
<!--      <video class="vst" src="../assets/images/index.mp4" webkit-playsinline="" playsinline="" x5-playsinline="" x-webkit-airplay="allow" muted="" loop autoplay="autoplay" width="100%" height="100%" id="guideVideo"></video>-->
  </div>
</template>

<script>
import lottie from 'lottie-web';

const loadingData = require('../assets/loading.json')

export default {
  props: ['value'],
  data() {
    return {
      isloging: true,
      animation: null,
    };
  },
  watch: {
    value(now, old) {
      this.isloging = now
    },
    isloging(now, old) {
      this.$emit('input', now)
    }
  },
  created() {},
  mounted() {
    // setTimeout(() => {
    //   if (!!this.isloging) {
    //     this.hideLoging();
    //   }
    // }, 7000);
    this.animation = lottie.loadAnimation({
      container: this.$refs.loading,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: loadingData,
      // path: './loading.json'
      rendererSettings: {}
    });
    this.animation.addEventListener('complete', () => {
      this.hideLoging();
    });
  },
  beforeDestroy() {
    this.animation.destroy();
  },
  methods: {
    hideLoging() {
      // this.isloging = false;
      this.$refs.loading.style.position = 'fixed';
      this.$refs.loading.style.zIndex = 1
      // $('#islogingId').css({'z-index': 1})
      this.$refs.loading.style.background = 'rgba(0,0,0,0)';
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      
    },
  },
};
</script>

<style lang="scss">
.loging {
  width: 100%;
  height: 100%;
  background: #204897;
  position: fixed;
  text-align: center;
  z-index: 99999;
  top: 0;
  left: 0;
  transition: all 1.5s;
  svg {
    width: 80% !important;
    max-width: 720px !important;
  }
}
.vst{
  width: 56%;
}
@media (max-width: 768px) {
  .loging {
    padding: 0;
    margin: 0;
    img {
      width: 7.5rem;
      margin-top: 35%;
    }
  }
}
</style>
