export const m = {
  langText: '中',
  backHome:"Back Home",
  imgs: [
    {
      img: require("../../assets/images/banner0.png"),
      imgMobile: require("../../assets/images/banner_mobile0.png"),
      styleType: 1,
      title_zh: "创意驱动美好生活",
      title_en: "Creativity drives a good life"
    },
    {
      img: require("../../assets/images/banner1.png"),
      imgMobile: require("../../assets/images/banner_mobile1.png"),
      styleType: 1,
      title_zh: "最具实战经验的文旅创意落地平台",
      title_en:
        "The most practical experience of cultural and tourism creative landing platform"
    },
    {
      img: require("../../assets/images/banner2.png"),
      imgMobile: require("../../assets/images/banner_mobile2.png"),
      styleType: 2,
      texts: [
        {
          title_zh: "创意·点燃花火",
          title_en: "Creativity · Lighting fireworks"
        },
        {
          title_zh: "创意·驱动生活",
          title_en: "Creativity · Drive life"
        },
        {
          title_zh: "创意·洞见世界",
          title_en: "Creativity · Insight into the world"
        }
      ]
    },
    {
      img: require("../../assets/images/banner3.png"),
      imgMobile: require("../../assets/images/banner_mobile3.png"),
      styleType: 2,
      texts: [
        {
          title_zh: "灵感智团",
          title_en: "Inspiration think tank"
        },
        {
          title_zh: "创意实践家",
          title_en: "Creative practitioner"
        },
        {
          title_zh: "幻想工程师",
          title_en: "Fantasy engineer"
        }
      ]
    },
    {
      img: require("../../assets/images/banner4.png"),
      imgMobile: require("../../assets/images/banner_mobile4.png"),
      styleType: 1,
      title_zh: "创意始于行走，实践源于洞察。",
      title_en:
        "Creativity starts with walking, and practice starts with insight."
    }
  ],
  sidebarList: [
    {
      name: "About",
      id: 0,
      path: '/company',
      nav: "#mainlist1",
      child: [
        {
          name: "Company",
          path: '/company',
          id: 0
        },
        {
          name: "Team",
          path: '/team',
          id: 1
        }
      ]
    },
    {
      name: "Business",
      id: 1,
      active: "",
      path: '/Planning',
      nav: "#mainlist2",
      child: [
        {
          name: "Planning ∞",
           path: '/Planning',
           id: 0
        },
      ]
    },
    {
      name: "Projects",
      id: 2,
      active: "",
      path: '/project',
      nav: "#mainlist3",
      child: [
        {
          name: "Urban & Rural",
          path: '/city?type_id=69',
          id: 0
        },
        {
          name: "Architecture",
           path: '/architecture?type_id=70',
           id: 1
        },
        {
          name: "Theme Park ",
           path: '/park?type_id=71',
           id: 1
        }
      ]
    },
    {
      name: "Vision",
      id: 3,
      active: "",
       path: '/news',
       nav: "#mainlist4",
      child: [
        {
          name: "News",
          path: '/news', 
          id: 0
        },
        {
          name: "Opinions",
          path: '/observe',
          id: 1
        },
        {
          name: "Repository",
          path: '/repository',
          id: 2
        }
      ]
    },
    {
      name: "Contact",
      id: 4,
      path: '/jobs',
      active: "",
      nav: "#mainlist5",
      child: [
        {
          name: "Join Us",
          active: "",
          path: '/jobs',
          id: 0
        },
        {
          name: "Contact",
          path: '/about',
          active: "",
          id: 1
        },
      ]
    }
  ],
  listData: [
    {
      title: "About bicc",
      content:
        'Following the concept of “Creativity Builds a Better Life”, bicc endeavors to build the most experienced platform for creative designs implementation.',
      list: [
        { name: "Company", path: "/company", active: "active", cName: "Creative Platform" },
        { name: "Team", path: "/team", cName: "Creative unit" },
        { name: "Client", path: "/resource", cName: "Creative outer brain" },
      ],
      styleCss: {
        top: "100%",
        backgroundImage: "url(" + require("../../assets/images/homeBg0.png") + ")",
      },
      moreText: "LEARN MORE "
    }, {
      title: "bicc Business",
      content:
        `By its “Planning∞”, bicc provides “specific studies” on architectural design, planning design, landscaping design, etc. It originates from design but focuses more on research and development, investment, construction, operation, and other stages.`,
      list: [{ name: "PLANNING ∞", path: "/Planning", active: "active" }],
      styleCss: {
        top: "200%",
        backgroundImage: "url(" + require("../../assets/images/homeBg1.png") + ")",
      },
      moreText: "LEARN MORE ",
    }, {
      title: "bicc Projects",
      content:
        `Driven by “Planning∞” and focusing on urban and rural development, architectural and park arrangement, bicc is specialized in project creative planning, to generate projects opportunities and values.`,
      list: [{ name: "Urban & Rural Planning",path:"/city", active: "active" }, { name: "Architecture Planning",path:"/architecture" }, { name: "Theme Park Planning" ,path:"/park"}],
      styleCss: {
        top: "300%",
        backgroundImage: "url(" + require("../../assets/images/homeBg0.png") + ")",
      },
      moreText: "LEARN MORE "
    }, {
      title: "bicc Insights",
      list: [
        { name: "News", path:"/news", active: "active", cName: "Communication Consulting" },
        { name: "Opinions", path:"/observe", cName: "Pass Value" },
        { name: "Repository", path:"/repository", cName: "Pass On The Dream" }
      ],
      content: 'bicc believes that “creativity” drives and keeps a project alive. We keep on exploring new ideas that generate new values, by constantly updating creative elements and their combinations',
      styleCss: {
        top: "400%",
        backgroundImage: "url("+  require("../../assets/images/homeBg3.png") + ")",
      },
      moreText: "LEARN MORE "
    },
    {
      title: "Heletica bicc",
      list: [
        { name: "Job",path:"/jobs", active: "active", cName: "" },
        { name: "Contact",path:"/about", cName: "" },
      ],
      content:
        "Welcome to know more about bicc and explore with us the endless possibilities for a better future.",
      styleCss: {
        top: "500%",
        backgroundImage: "url(" + require("../../assets/images/homeBg4.png") + ")"
      },
      moreText: "Learn more"
    }
  ],
  sidebarListIndex: [
    {
      name: "About",
      id: 0,
      nav: "#mainlist1",
    },
    {
      name: "Business",
      id: 1,
      active: "",
      nav: "#mainlist2",

    },
    {
      name: "Projects",
      id: 2,
      active: "",
      nav: "#mainlist3",

    },
    {
      name: "Vision",
      id: 3,
      active: "",
      nav: "#mainlist4",

    },
    {
      name: "Contact",
      id: 4,
      active: "",
      nav: "#mainlist5",
    }
  ],
  more: 'more',
  close: 'close',
  onHeaddata: {
    imgUrl: require('../../assets/images/head_img.png'),
    headTitZh: '以策划为源，衍化万物，为深远发展探溯顶层方略。',
    headTitEn: 'Take planification as a resource. Explore top strategies for long-term development.'
  },
  projectHeaddatc: {
    imgUrl: require('../../assets/images/b-r-4.png'),
    headTitZh: '热爱知识，更热爱让经验与实践的积累得以无限叠加。',
    headTitEn: 'We love knowledge, especially the experience through practice.'
  },
  projectHeaddatb: {
    imgUrl: require('../../assets/images/b-r-4.png'),
    headTitZh: '以全球化与在地化的视角创想未来。',
    headTitEn: 'Locally and internationally, we imagine the future.'
  },
  projectHeaddata: {
    imgUrl: require('../../assets/images/b-r-4.png'),
    headTitZh: '洞察万物，捕捉风向，谨慎思辨，付诸实践。',
    headTitEn: 'Discover. Locate. Think. Practice.'
  },
  projectHeaddata5: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
  },
  projectHeaddata6: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
    
    
  },
  projectName: '所有项目',
  projectSubName: 'PROJECTS',

  projectName1: '城乡策划',
  projectSubName1: 'URBAN & RURAL PLANNING',

  projectName2: '建筑策划',
  projectSubName2: 'Architecture Planning',

  projectName3: '乐园策划',
  projectSubName3: 'THEME PARK PLANNING',

  projectHeaddata7: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
    
  },

  projectHeaddata8: {
    imgUrl: require('../../assets/images/project_head_img.png'),
    headTitZh: '珍视每一次合作，与杰出伙伴彼此成就、共同成长。',
    headTitEn: `Through every cooperation,&nbsp we achieve and progress with you.`
    
  },
  repositoryHeaddata: {
    imgUrl: require('../../assets/images/repository_head_img.png'),
    headTitZh: '热爱知识， 更热爱让经验与眼界的积累得以无限叠加。',
    headTitEn: 'Love the knowledge, more love so that the accumulation of experience and vision can be infinitely superimposed.'
  },
  companyHeaddata: {
    imgUrl: require('../../assets/images/companyAddress.png'),
    headTitZh: '做生活的洞察者，拾起创意的火光，照亮未来。',
    headTitEn: 'Have an insight for life, ignite the sparkle of creativity, and light up the path to the future.'
  },
  companyHeaddata1: {
    imgUrl: require('../../assets/images/companyAddress.png'),
    headTitZh: '以数万小时共事项目的默契，齐心驱动创意。',
    headTitEn: 'Through thousands working hours as a team, we build creativity. '
  },
  
  companyHeaddata2: {
    imgUrl: require('../../assets/images/companyAddress.png'),
    headTitZh: '做生活的洞察者，拾起创意的火光，照亮未来。',
    headTitEn: 'To be an insight into life,Pick up the creative fire and illuminate the future.'
  },
  jobHeaddata: {
    imgUrl: require('../../assets/images/b-r-5.png'),
    headTitZh: '为事业征程寻觅旅伴，共赴美好生活目的地。',
    headTitEn: 'Be our partners on this journey to a better life.'
  },
  jobHeaddata1: {
    imgUrl: require('../../assets/images/b-r-5.png'),
    headTitZh: '从湾区远航，看世界归来。',
    headTitEn: 'Sail out from the bay to discover the world.'
  },
}
export default m